.header {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #ffffff10;
  z-index: 10;
}

.headerSticky {
  border-bottom: none;
  background: rgb(8,8,8);
  background: linear-gradient(0deg, rgba(8,8,8,0) 0%, rgba(8,8,8,1) 30%);
}

.wrapper {
  padding: 64px 0;
  padding-bottom: 29px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: 32px;
}

.projectName {
  width: max-content;

  font-size: 19px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Inter", "Arial", sans-serif;
}

.trademark {
  font-size: 10px;
  font-weight: 400;
}

.nav {
  justify-self: center;
}

.navList {
  display: flex;
  gap: 21%;
}

.item {
  position: relative;

  min-width: max-content;
}

.itemLink {
  font-size: 14px;
  font-weight: 600;
  color: #ffffff60;
  text-decoration: none;

  transition: color 0.25s;

  &:hover {
    color: #ffffff;
  }
}

.itemActive {
  .itemLink {
    color: #ffffff;
  }

  .itemIcon {
    transform: rotate(180deg);

    path {
      fill: #ffffff;
    }
  }
}

.itemLinkActive {
  position: relative;

  color: #ffffff;

  pointer-events: none;

  &::before {
    position: absolute;
    top: 50%;
    right: calc(100% + 15px);

    display: block;
    width: 24px;
    height: 2px;

    background-color: #0c22bf;

    content: "";
  }
}

.itemMany {
  .itemIcon {
    margin-left: 5px;
  }
}

.innerItemList {
  position: absolute;
  top: 100%;
  padding: 8px;

  width: max-content;

  background-color: rgba(8, 8, 8, 0.6);
  box-shadow: 0 0 60px #080808;
  border-radius: 4px;
}

.innerItemLink {
  padding: 8px 0;

  display: inline-block;
  width: 100%;

  font-size: 14px;
  color: #ffffff90;
  text-decoration: none;

  transition: color 0.25s;

  &:hover {
    color: #ffffff;
  }
}

.buttonOpenFormWrap {
  justify-self: right;
}

// Button hamburger styles start
.navContainer {
  position: relative;

  z-index: 2;
}

.navContainer .hamburgerLines {
  margin: 0 auto;
  height: 10px;
  width: 16px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;
}

.navContainer .hamburgerLines .line {
  display: block;
  height: 2px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 3px;
}

.navContainer .hamburgerLines .line1 {
  transform-origin: 0 0;
  transition: transform 0.4s ease-in-out;
}

.navContainer .hamburgerLines .line2 {
  align-self: end;
  width: 100%;

  transition: transform 0.2s ease-in-out;
}

.navContainer .hamburgerLines .line3 {
  transform-origin: 0 100%;
  transition: transform 0.4s ease-in-out;
}

.navContainer input[type="checkbox"]:checked ~ .hamburgerLines {
  //transform: translateX(2px);
}

.navContainer input[type="checkbox"]:checked ~ .hamburgerLines .line1 {
  width: 12px;
  transform: rotate(45deg);
}

.navContainer input[type="checkbox"]:checked ~ .hamburgerLines .line2 {
  transform: scaleY(0);
}

.navContainer input[type="checkbox"]:checked ~ .hamburgerLines .line3 {
  width: 12px;
  transform: rotate(-45deg);
}
// button hamburger styles end

.navModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 170px 33px;
  padding-bottom: 50px;

  color: #ffffff;

  background-color: #000000;

  z-index: 1;
  overflow: auto;
}

.innerItemListMobile {
  margin-top: 20px;
}

.itemMobile {
  margin-bottom: 21px;
}

.itemLinkMobile {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
}

.innerItemMobile {
  margin-bottom: 23px;
}

.ArrowDownWrap {
  margin-right: 28px;

  display: inline-grid;
  place-items: center;
  width: 22px;
  height: 22px;

  background-color: #ffffff10;
  border-radius: 50%;
}

.innerItemLinkMobile {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff80;
  text-decoration: none;
}

.btnContact {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;

  background-color: transparent;
  border: none;
}

@media (max-width: 1300px) {
  .wrapper {
    grid-template-columns: auto auto auto;
  }

  .nav {
    justify-self: auto;
  }

  .navList {
    flex-wrap: wrap;
    gap: 5%;
  }
}

@media (max-width: 991px) {
  .wrapper {
    padding-right: 0;
    padding-bottom: 13px;
    grid-template-columns: auto auto;
    justify-content: space-between;
  }

  .projectName {
    font-size: 14px;

    z-index: 2;
  }

  .trademark {
    font-size: 7px;
  }
}
