.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(8, 8, 8, 0.6);
  backdrop-filter: blur(100px);
  overflow: auto;
  z-index: 10;
}

.container {
  position: relative;
}

.closeBtn {
  display: none;
  position: absolute;
  background-color: transparent;
  background-image: url("../../../assets/images/icon-close.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: none;
  cursor: pointer;
  z-index: 10;
}
