.link {
  display: grid;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  border: 0;
  border-radius: 50%;
  background-color: #ffffff10;

  cursor: pointer;

  transition: background-color 0.25s;

  &:hover {
    background-color: #ffffff20;
  }
}

@media (max-width: 768px) {
  .link {
    width: 22px;
    height: 22px;

    & svg {
      width: 7px;
      height: 8px;
    }
  }
}
