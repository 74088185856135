.article {
  padding-top: 95px;
  padding-bottom: 316px;
}

.linkBack {
  margin-bottom: 54px;

  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;

  background-color: #ffffff10;
  border-radius: 50%;

  & svg {
    transform: rotate(90deg);
  }
}

.date {
  position: relative;
  margin-bottom: 47px;
  padding-bottom: 38px;

  width: max-content;

  color: #b3b3be;
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;

    display: block;
    width: 35%;
    height: 1px;

    background-color: #8c9094;

    content: "";
  }
}

.content {
  display: grid;
  align-items: start;
  grid-template-columns: 497px 1fr;
  gap: 89px;
}

.tag {
  margin-bottom: 40px;
  padding: 8px 20px;

  width: max-content;

  font-size: 55px;
  font-weight: 800;
  line-height: 1;
  text-transform: uppercase;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;

  background-image: url("../../assets/images/tag-bg.svg");
  background-size: 100% 100%;
}

.title {
  margin-bottom: 28px;

  font-size: 54px;
}

.img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.textWrap {
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

@media (max-width: 1200px) {
  .content {
    grid-template-columns: 347px 1fr;
    gap: 40px;
  }

  .title {
    font-size: 40px;
  }
}

@media (max-width: 991px) {
  .content {
    grid-template-columns: 1fr;
  }

  .img {
    max-width: 494px;
  }
}

@media (max-width: 768px) {
  .article {
    padding-top: 56px;
    padding-bottom: 84px;
  }

  .tag {
    margin-bottom: 29px;

    font-size: 36px;
  }

  .title {
    font-size: 30px;
  }

  .date {
    margin-bottom: 34px;
    padding-bottom: 0;

    font-size: 9px;

    &::after {
      display: none;
    }
  }
}
