.implement {
  padding-top: 160px;
  background-color: #121212;
}

.cards {
  padding-bottom: 160px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(423px, 1fr));
  gap: 12%;
}

.itemSubtitle1,
.itemSubtitle2,
.itemSubtitle3 {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.itemSubtitle1 {
  color: #1932e2;
}

.itemSubtitle2 {
  color: #2990f8;
}

.itemSubtitle3 {
  color: #08d0ee;
}

.cardWithArrow {
  position: relative;

  &::before {
    position: absolute;
    top: -28px;
    right: 0;
    transform: rotate(32deg);

    display: block;
    width: 217px;
    height: 137px;

    background-image: url("../../assets/images/arrow-right.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    content: "";
  }
}

@media (max-width: 2060px) {
  .cards {
    gap: 5%;
  }

  .cardWithArrow {
    &::before {
      top: -28px;

      width: 177px;
      height: 117px;
    }
  }
}

@media (max-width: 991px) {
  .cards {
    padding-left: 40px;

    gap: 137px;
  }

  .cardWithArrow {
    &::before {
      top: calc(85%);
      right: auto;
      left: -120px;
      transform: rotate(55deg) scale(1, -1);
    }
  }
}

@media (max-width: 768px) {
  .implement {
    padding-top: 96px;
  }

  .cards {
    padding-bottom: 92px;

    grid-template-columns: 1fr;
    gap: 37px;
  }
}
