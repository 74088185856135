.button {
  padding: 16px 55px;
  padding-bottom: 22px;

  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Inter", "Arial", sans-serif;

  background-color: transparent;
  background-image: url("../../../assets/images/bg-button.svg");
  border: none;

  cursor: pointer;

  transition: opacity 0.25s;

  &:hover {
    opacity: 0.75;
  }
}

.buttonLight {
  background-image: url("../../../assets/images/bg-button-light.svg");
}

@media (max-width: 991px) {
  .button {
    padding: 10px 32px;
    padding-bottom: 15px;

    font-size: 9px;
  }
}
