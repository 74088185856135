.footer {
  padding-top: 160px;
  padding-bottom: 80px;
}

.top {
  margin-bottom: 160px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleWrap {
  display: flex;
  align-items: start;
  gap: 9px;
}

.contactBtn {
  padding: 45px 26px;

  width: max-content;

  color: #ffffff;
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-family: "Inter", "Arial", sans-serif;

  background-color: transparent;
  background-image: url("../../assets/images/bg-button2.svg");
  background-repeat: no-repeat;
  border: none;

  cursor: pointer;

  transition: opacity 0.25s;

  &:hover {
    opacity: 0.75;
  }
}

.title {
  margin-top: 34px;

  font-size: 90px;
  font-weight: 700;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.contactLink {
  margin-top: 15px;

  display: flex;
  align-items: center;
  gap: 23px;

  text-decoration: none;

  transition: opacity 0.25s;

  &:hover {
    opacity: 0.75;
  }
}

.contactInner {
  display: grid;

  text-align: right;

  & span:first-child {
    font-size: 20px;
    color: #ffffff80;
    font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
  }

  & span:last-child {
    font-size: 13px;
    color: #ffffff40;
  }
}

.contactIconWrap {
  display: grid;
  place-items: center;
  width: 47px;
  height: 47px;

  background-color: #ffffff10;
  border-radius: 50%;
}

.contactIcon {
  transform: rotate(-135deg);
}

.address {
  margin-bottom: 143px;

  max-width: 240px;

  font-size: 18px;
  color: #ffffff80;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.bottom {
  padding-top: 29px;

  display: flex;
  justify-content: space-between;

  border-top: 1px solid #ffffff15;
}

.name {
  font-size: 19px;
  font-weight: 700;
  text-transform: uppercase;
}

.trademark {
  padding-left: 5px;

  font-size: 10px;
}

.copyright {
  font-size: 14px;
  color: #ffffff80;
}

@media (max-width: 991px) {
  .contactBtn {
    padding: 37px 18px;
  }

  .title {
    font-size: 60px;
  }
}

@media (max-width: 768px) {
  .footer {
    padding-top: 74px;
    padding-bottom: 163px;
  }

  .contactBtn {
    padding: 25px 10px;

    font-size: 8px;
  }

  .title {
    font-size: 53px;
  }

  .address {
    margin-bottom: 0;

    font-size: 14px;
  }
}
