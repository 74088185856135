._container {
  margin: 0 auto;
  padding-right: 40px;
  padding-left: 170px;

  max-width: 1550px;
}

@media (max-width: 1400px) {
  ._container {
    padding-right: 30px;
    padding-left: 40px;
  }
}
