.container {
  padding: 0 60px;
  padding-bottom: 100px;
  width: 100%;
  max-width: 1222px;
  height: 100%;
}

.contactForm {
  width: 100%;
}

.header {
  margin-bottom: 188px;
  padding: 70px 0;
  padding-bottom: 50px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #ffffff10;
}

.projectName {
  font-size: 19px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "Inter", "Arial", sans-serif;
}

.trademark {
  font-size: 10px;
  font-weight: 400;
}

.btn {
  background-color: transparent;
  border: 0;

  cursor: pointer;

  &:hover svg {
    path {
      fill-opacity: 1;
    }
  }
}

.title {
  font-size: 91px;
  text-transform: uppercase;
  font-family: 'Inter', 'Arial', sans-serif;
}

.titleBorder {
  background: -webkit-linear-gradient(0deg, #3D67FF 10%, #00E0FF 91%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 1.4px transparent;
  color: #121212;
}

.titleUnderline {
  padding-bottom: 5px;
  background-image: url("../../../assets/images/underline.svg");
  background-size: 100% 19px;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.subtitle {
  margin-bottom: 173px;

  font-size: 40px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 2.7px;
}

.formContainer {
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 1.5fr;
}

.appeal {
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 21px;

  color: #ffffff80;
  font-size: 25px;
}

.iconWrap {
  transform: rotate(-90deg);

  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;

  background-color: #ffffff10;
  border-radius: 50%;
}

.floatingLabelGroup {
  position: relative;
  margin-bottom: 20px;
}

.input {
  margin-bottom: 14px;
  padding: 24px 0;

  width: 100%;

  color: #ffffff;
  font-size: 22px;

  background-color: transparent;
  border: none;
  border-bottom: 1px solid #ffffff10;

  &:focus {
    border-bottom: 1px solid #0c22c090;
  }

  &::placeholder {
    color: transparent;
  }
}

.textarea {
  margin-top: 20px;
  padding: 0;
  padding-bottom: 10px;

  resize: vertical;
}

.floatingLabel {
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 24px;
  transition: 0.2s ease all;

  color: #ffffff80;
  font-size: 22px;
  font-weight: 300;
}

.input:focus ~ .floatingLabel,
.input:not(:placeholder-shown) ~ .floatingLabel {
  top: -5px;
  font-size: 14px;
}

.submitBtn {
  padding: 20px 30px;

  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 20px;

  color: #ffffff;
  font-size: 15px;
  letter-spacing: 3px;
  text-transform: uppercase;

  background-color: #0c22c0;
  border: 11px solid #1B1D26;
  border-radius: 100px;

  cursor: pointer;
  transition: opacity 0.25s;

  & svg {
    transform: rotate(-90deg);

    & path {
      fill: #ffffff;
    }
  }

  &:hover {
    opacity: 0.75;
  }
}

@media (max-width: 1080px) {
  .formContainer {
    grid-template-columns: 1fr;
  }

  .appeal {
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 0 34px;
  }

  .header {
    margin-bottom: 78px;

    padding: 39px 0;
    padding-bottom: 18px;
  }

  .title {
    font-size: 29px;
  }

  .titleUnderline {
    background-size: 100% 11px;
  }

  .subtitle {
    margin-bottom: 61px;

    font-size: 16px;
    color: #ffffff80;
  }

  .iconWrap {
    transform: rotate(0);

    width: 22px;
    height: 22px;

    & svg {
      width: 7px;
      height: 8px;
    }
  }

  .appeal {
    margin-bottom: 53px;
  }

  .appealText {
    display: none;
  }

  .input {
    padding: 16px 0;
    padding-top: 4px;

    font-size: 16px;
  }

  .textarea {
    margin-top: 0;
  }

  .floatingLabel {
    top: 2px;
    font-size: 14px;
  }

  .input:focus ~ .floatingLabel,
  .input:not(:placeholder-shown) ~ .floatingLabel {
    top: -16px;

    font-size: 11px;
  }

  .submitBtn {
    padding: 15px 20px;

    font-size: 11px;

    border: 6px solid #1B1D26;
  }
}
