.insightsSlider {
  padding-top: 160px;
  padding-bottom: 160px;

  background-color: #121212;
}

.title {
  margin-bottom: 18px;
  font-size: 90px;
  font-family: 'Inter', 'Arial', sans-serif;
}

.titleBorder {
  background: -webkit-linear-gradient(0deg, #3D67FF 10%, #00E0FF 91%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 1.4px transparent;
  color: #121212;
}

.titleUnderline {
  padding-bottom: 5px;
  background-image: url("../../assets/images/underline.svg");
  background-size: 100% 19px;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.subtitle {
  margin-bottom: -22px;

  font-size: 18px;
  color: #ffffff80;
  font-weight: 600;
  letter-spacing: 2.7px;
  text-transform: uppercase;
}

.slider {
  width: 100%;
  height: 100%;
}

.slide {
  max-width: 425px;

  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.slideImg {
  margin-bottom: 52px;

  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.slideTitle {
  margin-bottom: 20px;

  min-height: 50px;

  font-size: 20px;
  font-weight: 700;
}

.slideText {
  margin-bottom: 31px;

  min-height: 230px;

  color: #ffffff50;
  font-weight: 500;

  border-bottom: 1px solid #ffffff10;
}

.slideLink {
  display: flex;
  gap: 20px;
  align-items: center;
  width: max-content;

  color: #ffffff;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-decoration: none;


  transition: opacity 0.25s;

  &:hover {
    opacity: 0.75;
  }
}

.slideLinkArrowWrap {
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;

  background-color: #ffffff10;
  border-radius: 50%;

  & svg {
    transform: rotate(-90deg);
  }
}

.navigation {
  margin-bottom: 141px;
  margin-top: 22px;
  margin-left: auto;

  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  max-width: 96px;

  z-index: 1;
}

.navBtn {
  width: 40px;
  height: 40px;

  font-size: 14px;

  background-color: #ffffff10;
  border-radius: 50%;
  border: none;

  cursor: pointer;

  transition: background-color 0.25s;

  &:hover {
    background-color: #ffffff20;
  }

  &:disabled {
    cursor: auto;
  }
}

.navPrev {
  svg {
    transform: rotate(90deg);
  }
}

.navNext {
  svg {
    transform: rotate(-90deg);
  }
}

@media (max-width: 1600px) {
  .slide {
    max-width: 250px;
  }
  .slideTitle {
    font-size: 1rem;
  }
  .slideText {
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .insightsSlider {
    padding-top: 102px;
    padding-bottom: 127px;
  }

  .title {
    margin-bottom: 66px;

    font-size: 45px;
  }

  .titleUnderline {
    background-size: 100% 11px;
  }

  .subtitle {
    margin-bottom: -43px;

    font-size: 10px;
    letter-spacing: 1px;
  }

  .slider {
    padding: 0 20px;
  }

  .slide {
    max-width: 333px;
  }

  .slideImg {
    margin-bottom: 40px;
  }

  .slideTitle {
    font-size: 18px;
  }

  .navigation {
    margin-bottom: 28px;

    max-width: 65px;
  }

  .slideText {
    font-size: 14px;

    min-height: 150px;

    border-bottom: none;
  }

  .slideLink {
    gap: 14px;

    font-size: 12px;
  }

  .slideLinkArrowWrap {
    width: 27px;
    height: 27px;

    & svg {
      width: 9px;
      height: 11px;
    }
  }

  .navBtn {
    width: 27px;
    height: 27px;

    & svg {
      width: 9px;
      height: 11px;
    }
  }
}
