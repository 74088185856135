.container {
  padding-top: 160px;
}

.cards {
  padding-bottom: 160px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 2.2%;
}

.itemSubtitle {
  margin-bottom: 39px;

  color: #2d46fa;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .container {
    padding-top: 81px;
  }

  .cards {
    padding-bottom: 81px;

    grid-template-columns: repeat(auto-fit, minmax(288px, 1fr));
    gap: 40px;
  }
}
