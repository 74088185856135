.container {
  padding-top: 160px;
}

.cards {
  padding-bottom: 160px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 11%;
}

.itemSubtitle1,
.itemSubtitle2,
.itemSubtitle3 {
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.itemSubtitle1 {
  color: #1932e2;
}

.itemSubtitle2 {
  color: #2990f8;
}

.itemSubtitle3 {
  color: #08d0ee;
}

.cardWithArrow {
  position: relative;

  &::before {
    position: absolute;
    top: 50px;
    right: 0;

    display: block;
    width: 230px;
    height: 54px;

    background-image: url("../../assets/images/arrow-right2.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    content: "";
  }
}

@media (max-width: 2060px) {
  .cards {
    gap: 5%;
  }

  .cardWithArrow {
    &::before {
      top: 50px;

      width: 177px;
      height: 37px;
    }
  }
}

@media (max-width: 991px) {
  .cards {
    padding-left: 40px;

    gap: 137px;
  }

  .cardWithArrow {
    &::before {
      top: calc(100%);
      right: auto;
      left: -130px;
      transform: rotate(90deg) scale(1, -1);
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding-top: 91px;
  }

  .cards {
    padding-bottom: 96px;

    grid-template-columns: 1fr;
    gap: 37px;
  }
}
