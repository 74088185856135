.mainSlider {
  position: relative;
}

.container {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);

  width: 100%;

  z-index: 2;
}

// slider
.slider {
  //position: absolute;
}
.slide {
  position: relative;

  min-height: 1284px;
}

.sliderImg {
  position: absolute;
  top: 0;
  left: 0;

  display: block;
  width: 100%;
  height: 100%;

  object-fit: cover;
}

.navigation {
  margin-top: 22px;

  display: grid;
  justify-content: space-between;
  grid-template-columns: auto auto;
  max-width: 98px;

  z-index: 1;
}

.navBtn {
  width: 44px;
  height: 44px;

  display: grid;
  place-items: center;

  font-size: 14px;

  background-color: #ffffff10;
  border-radius: 50%;
  border: none;

  cursor: pointer;

  &:hover {
    background-color: #ffffff20;
  }

  &:disabled {
    background-color: #ffffff10;

    cursor: auto;
  }
}

.navPrev {
  svg {
    transform: rotate(90deg);
  }
}

.navNext {
  svg {
    transform: rotate(-90deg);
  }
}
// end slider

// slide 1
.bigText {
  font-size: 103px;
  font-weight: 600;
  line-height: 1.1;
}

.bigTextPart {
  text-decoration: line-through;
  opacity: 0.7;
}

.aiChangedText {
  font-size: 52px;
  font-weight: 200;

  b {
    font-weight: 600;
  }
}
// end slide 1

@media (max-width: 768px) {
  .slide {
    padding-top: 300px;
    min-height: 634px;
  }

  .navigation {
    display: none;
  }

  .bigText {
    font-size: 42px;
  }

  .aiChangedText {
    margin-bottom: 40px;
    padding-bottom: 40px;

    font-size: 21px;
  }
}
