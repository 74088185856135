.servicesCard {
  height: 100%;

  border-bottom: 1px solid #2d2d2d;
}

.counter {
  margin-bottom: 12px;
  padding: 13px 14px;

  display: flex;
  place-items: center;
  width: max-content;

  font-size: 14px;
  color: #ffffff50;

  border-radius: 50%;
  background-color: #ffffff10;
}

.title {
  margin-bottom: 5px;

  font-size: 70px;
  font-weight: 700;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.titlePart2 {
  font-weight: 200;
  color: #ffffff50;
  letter-spacing: -2px;
}

.subtitle {
  margin-bottom: 39px;
}

.text {
  margin-bottom: 136px;

  max-width: 360px;

  color: #ffffff60;
  line-height: 1.25;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

@media (max-width: 768px) {
  .servicesCard {
    display: grid;
    align-items: start;
    grid-template-columns: 40px 1fr;
  }

  .servicesCardWithArrow {
    display: block;
  }

  .counter {
    margin-top: 15px;
    padding: 7px 8px;

    font-size: 10px;
  }

  .title {
    font-size: 36px;
  }

  .subtitle {
    margin-bottom: 20px;

    font-size: 10px;
  }

  .text {
    margin-bottom: 27px;

    font-size: 14px;
  }
}
