.servicesTop {
  margin-bottom: 162px;
  padding-bottom: 68px;

  border-bottom: 1px solid #ffffff15;
}

.buttonWrap {
  display: flex;
  align-items: start;
  justify-content: space-between;
}

.label {
  margin-top: 100px;
  margin-bottom: 9px;

  font-size: 14px;
  font-weight: 300;
  color: #ffffff60;
  letter-spacing: 9px;
  text-transform: uppercase;
}

.title {
  margin-bottom: 30px;
  font-size: 90px;
  font-weight: 700;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.textWrap {
  display: grid;
  grid-template-columns: 76px 1fr;
  align-items: start;
  max-width: 780px;

  font-weight: 500;
  color: #ffffff60;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

@media (max-width: 768px) {
  .servicesTop {
    margin-bottom: 41px;
  }

  .label {
    margin-top: 0;

    font-size: 10px;
    letter-spacing: 2px;
  }

  .title {
    font-size: 42px;
  }

  .textWrap {
    grid-template-columns: 40px 1fr;

    font-size: 14px;
  }
}
