.howWeDeliver {
  padding-top: 160px;
  padding-bottom: 160px;

  background-color: #121212;
}

.top {
  padding-bottom: 72px;

  border-bottom: 1px solid #ffffff10;
}

.title {
  font-size: 90px;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.titleBorder {
  background: -webkit-linear-gradient(0deg, #3D67FF 10%, #00E0FF 91%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 1.4px transparent;
  color: #121212;
}

.titleUnderline {
  padding-bottom: 5px;
  background-image: url("../../assets/images/underline.svg");
  background-size: 100% 19px;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.subtitle {
  margin-bottom: 105px;

  color: #ffffff50;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.descWrap {
  padding-top: 70px;
  padding-bottom: 116px;

  display: grid;
  grid-template-columns: auto 680px;

  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;

  border-bottom: 1px solid #ffffff10;
}

.descLabel {
  font-size: 22px;
  font-weight: 600;
}

.descTextWrap {
  padding-top: 5px;

  display: grid;
  gap: 23px;

  font-size: 18px;
  color: #ffffff80;
}

.accordionWrap {
  display: grid;
  grid-template-columns: auto 680px;
}

.accordionWrapLabel {
  margin-top: 31px;
  padding: 5px 10px;

  width: max-content;
  height: max-content;

  font-size: 22px;
  font-weight: 600;

  background-image: url("../../assets/images/underline2.svg");
  background-size: 100% 4px;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.accordion {
  margin-bottom: 48px;

  color: #ffffff;
}

.accordionItem {
  padding: 23px 0;

  border-bottom: 1px solid #ffffff10;
}

.accordionHead {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;

  color: #ffffff;
  text-decoration: none;

  cursor: pointer;
}

.accordionLabel {
  font-size: 50px;
  font-weight: 600;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.labelDivider {
  color: #1345fc;
  font-family: "Inter", "Arial", sans-serif;
}

.accordionButton {
  position: relative;

  display: grid;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  background-color: #ffffff10;
  border-radius: 50%;
  border: 0;

  cursor: inherit;
}

.accordionItemOpen {
  & .accordionButton {
    & svg {
      transform: rotate(-180deg);
    }
  }
}

@media (max-width: 991px) {
  .howWeDeliver {
    padding-top: 81px;
    padding-bottom: 87px;
  }

  .top {
    padding-bottom: 0;

    display: grid;

    border-bottom: none;
  }

  .title {
    margin-bottom: 28px;

    font-size: 32px;
  }

  .titleUnderline {
    background-size: 100% 6px;
  }

  .subtitle {
    margin-bottom: 11px;

    order: -1;

    font-size: 10px;
  }

  .descWrap,
  .accordionWrap {
    grid-template-columns: 1fr;
  }

  .descWrap {
    padding-bottom: 66px;

    border-bottom: 0;
  }

  .descLabel {
    margin-bottom: 27px;

    font-size: 14px;
  }

  .descTextWrap {
    font-size: 14px;

    gap: 10px;
  }

  .accordion {
    margin-bottom: 0;
    padding-left: 35px;
  }

  .accordionHead {
    grid-template-columns: 1fr auto;
  }

  .accordionWrapLabel {
    padding: 0;

    font-size: 14px;

    background: 0;
  }

  .accordionLabel {
    font-size: 20px;
  }

  .accordionButton {
    width: 22px;
    height: 22px;

    & svg {
      width: 7px;
      height: 8px;
    }
  }
}


