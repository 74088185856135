.topPage {
  padding-top: 146px;
  padding-bottom: 160px;
}

.top {
  padding-bottom: 61px;

  border-bottom: 1px solid #ffffff10;
}

.mainPage {
  background-image: url("../../assets/images/bg-top.png");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  position: fixed;
  top: -100px;
  width: 120%;
  height: 120%;
  transform: translatey(0px);
	animation-name: floating;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
}

@keyframes floating {
  0% { transform: translate(0,  0px) rotate(-0deg);}
  50%  { transform: translate(0, 100px) rotate(-15deg);}
  100%   { transform: translate(0, -0px) rotate(-0deg);}    
}

@media (max-width: 768px) {
  .mainPage {
    background-image: url("../../assets/images/bg-top.png");
    background-position: center 160px;
    background-repeat: no-repeat;
    background-size: 120%;
  }
}

.name0 {
  --index: 0;
}

.name1 {
  --index: 1;
}

.name2 {
  --index: 2;
}

.name {
  font-size: 118px;
  font-weight: 700;
  line-height: 1.1;
  --stacks: 3;
  display: grid;
  grid-template-columns: 1fr;
}

.name span {
  grid-row-start: 1;
  grid-column-start: 1;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(.46,.29,0,1.24) 1 backwards calc(var(--index) * 120ms), glitch 2s ease infinite 2s alternate-reverse;
}

.name span:nth-child(odd) { --glitch-translate: 8px; }
.name span:nth-child(even) { --glitch-translate: -8px; }

@keyframes name {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  };
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%, 100% {  text-shadow: none; transform: none; }
}

.namePart {
  font-weight: 200;
}

.trademark {
  position: relative;
  top: -21px;
  left: 6px;
  font-size: 36px;
  font-weight: 200;
  color: #ffffff50;
}

.textUnderName {
  margin-bottom: 76px;
  font-size: 36px;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
}

.main {
  padding-top: 75px;

  display: grid;
  grid-template-columns: 1fr 1.6fr;
}

.inspiredText {
  margin-top: 35px;
  font-size: 22px;
  font-weight: 300;
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;

  b {
    font-weight: 600;
  }
}

.bigText {
  font-size: 100px;
  font-weight: 600;
  line-height: 1.1;
}

.bigTextPart {
  color: #ffffff;
  text-decoration: line-through;

  opacity: 0.7;
}

.aiChangedText {
  margin-bottom: 67px;
  padding-bottom: 98px;

  font-size: 52px;
  font-weight: 200;

  border-bottom: 1px solid #ffffff10;

  b {
    font-weight: 600;
  }
}

.description {
  margin-bottom: 46px;

  font-size: 25px;
  letter-spacing: -0.8px;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Plus Jakarta Sans', 'Arial', sans-serif;
}

.readMore {
  display: flex;
  gap: 20px;
  align-items: center;
  width: max-content;

  color: #ffffff;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-decoration: none;
}

.iconWrap {
  padding: 13px 17px;

  font-size: 14px;

  background-color: #ffffff10;
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .main {
    grid-template-columns: 1fr;
  }

  .inspiredText {
    order: 1;
  }
}

@media (max-width: 991px) {
  .topPage {
    padding-top: 35px;
  }

  .top {
    padding-bottom: 44px;

    border-bottom: none;
  }

  .btnWrap {
    margin-bottom: 107px;
    margin-left: auto;

    width: max-content;
  }

  .textUnderName {
    margin-bottom: 37px;
  }

  .description {
    padding-top: 38px;
    border-top: 1px solid #ffffff10;
  }
}

@media (max-width: 768px) {
  .topPage {
    padding-bottom: 76px;
  }

  .name {
    font-size: 60px;
  }

  .trademark {
    font-size: 18px;
  }

  .textUnderName {
    font-size: 18px;
  }

  .bigText {
    font-size: 42px;
  }

  .aiChangedText {
    margin-bottom: 40px;
    padding-bottom: 40px;

    font-size: 21px;
  }

  .description {
    font-size: 16px;
  }

  .inspiredText {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.80);
  }
}