.mainPage {
  background-image: url("../../assets/images/bg-top.png");
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 768px) {
  .mainPage {
    background-image: url("../../assets/images/bg-top.png");
    background-position: center 160px;
    background-repeat: no-repeat;
    background-size: 120%;
  }
}
